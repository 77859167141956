<template>
    <div>
        <div class="filter-panel">
            <input
                type="text"
                v-model="queryParams.serviceId"
                class="cs-input"
                style="margin: 0 20px 15px 0;"
                placeholder="搜索serviceId"
            />
            <input
                type="text"
                v-model="queryParams.name"
                class="cs-input"
                style="margin: 0 20px 15px 0;"
                placeholder="搜索资源名称"
            />
            <input
                v-model="queryParams.path"
                type="text"
                class="cs-input"
                style="margin: 0 20px 15px 0;"
                placeholder="搜索资源路径"
            />
            <span style="margin-right: 0;">
                菜单资源：
            </span>
            <div class="cs-select" style="margin: 0 20px 15px 0;">
                <select v-model="queryParams.menuItem">
                    <option value="">请选择</option>
                    <option :value="true">是</option>
                    <option :value="false">否</option>
                </select>
                <i class="icon el-icon-caret-bottom"></i>
            </div>
            <span style="margin-right: 0;">
                启用状态：
            </span>
            <div class="cs-select" style="margin: 0 10px 15px 0;">
                <select v-model="queryParams.enable">
                    <option value="">请选择</option>
                    <option :value="true">已启用</option>
                    <option :value="false">未启用</option>
                </select>
                <i class="icon el-icon-caret-bottom"></i>
            </div>
            <button class="btn btn-primary ml-4" @click="getResourceList">
                查询
            </button>
        </div>

        <div class="result-panel">
            <div class="result-panel-header text-right">
                <button class="btn btn-primary" @click="openAddResourceModal">
                    <svg
                        class="icon"
                        aria-hidden="true"
                    >
                        <use xlink:href="#icon-menujiahao"></use>
                    </svg>
                    添加资源
                </button>
            </div>
            <div class="row pt-3 pl-3 pr-3 pd-3">
                <div>
                    <ul class="list-group">
                        <li
                            v-for="resource in resourceList"
                            :key="resource.id"
                            :class="[
                                'list-group-item',
                                { active: childrenResource.id === resource.id },
                            ]"
                            @click="lookChildrenResource(resource)"
                            style="border: 1px solid #f0f0f0;"
                        >
                            <p style="width: 100%; position: relative;">
                                名称：{{ resource.name }}
                                <template v-if="resource.menuItem">
                                    <el-tag type="danger"
                                        style="border-radius: 15px; border: 1px solid #fef0f0; height: 20px; width: 50px; line-height: 18px; text-align: center; position: absolute; right: 0;"
                                        >菜单</el-tag
                                    >
                                </template>
                            </p>
                            <div style="width: 416px; display: flex; justify-content: space-between; align-items: center;">
                                <div>
                                    路径：{{ resource.path }}
                                </div>
                                <div>
                                    <div class="btn-group">
                                        <button
                                            type="button"
                                            class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            操作
                                        </button>
                                        <ul
                                            class="dropdown-menu"
                                            style="width: 115px;"
                                        >
                                            <li
                                                @click="openEidtResourceModal(resource)"
                                            >
                                                <a
                                                    style="width: 100%;"
                                                >修改</a>
                                            </li>
                                            <li
                                                @click="openChangeEnableModal(resource.id, false)"
                                            >
                                                <a
                                                    style="width: 100%;"
                                                >禁用</a>
                                            </li>
                                            <li
                                                @click="openChangeEnableModal(resource.id, true)"
                                            >
                                                <a
                                                    style="width: 100%;"
                                                >启用</a>
                                            </li>
                                            <li
                                                @click="openAddChildrenResourceModal(resource.id)"
                                            >
                                                <a
                                                    style="width: 100%;"
                                                >添加下级资源</a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </li>
                    </ul>
                </div>

                <div>
                    <ul class="list-group">
                        <li
                            class="list-group-item"
                            v-for="resource in childrenResource.resources"
                            :key="resource.id"
                        >
                            <p>名称：{{ resource.name }}</p>
                            <div style="width: 425px; display: flex; justify-content: space-between; align-items: center;">
                                <div>
                                    路径：{{ resource.path }}
                                </div>
                                <div>
                                    <div class="btn-group">
                                        <button
                                            type="button"
                                            class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            操作
                                        </button>
                                        <ul
                                            class="dropdown-menu"
                                            style="width: 85px;"
                                        >
                                            <li
                                                @click="openEidtResourceModal(resource)"
                                            >
                                                <a
                                                    style="width: 100%;"
                                                >修改</a>
                                            </li>
                                            <li
                                                v-if="resource.enable"
                                                @click="openChangeEnableModal(resource.id,false)"
                                            >
                                                <a
                                                    style="width: 100%;"
                                                >禁用</a>
                                            </li>
                                            <li
                                                v-else
                                                @click="openChangeEnableModal(resource.id, true)"
                                            >
                                                <a
                                                    style="width: 100%;"
                                                >启用</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- 资源创建 -->
        <CSDialog
            :dialogTitle="addEditTitle"
            :dialogVisible="addResourceModalVisible"
            dialogWidth="580px"
            @onClose="addResourceModalVisible = false"
            @onConfirm="createOrEditResource"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="margin-bottom: 23px;">
                    <label style="width: 120px; text-align: right; margin-right: 40px;">serviceId</label>
                    <input
                        type="text"
                        v-model="addResourceParams.serviceId"
                        style="border: 1px solid #999; border-radius: 4px; "
                        placeholder="请输入"
                    />
                </div>
                <div style="margin-bottom: 23px;">
                    <label style="width: 120px; text-align: right; margin-right: 40px;">资源名称</label>
                    <input
                        type="text"
                        v-model="addResourceParams.name"
                        style="border: 1px solid #999; border-radius: 4px;"
                        placeholder="请输入"
                    />
                </div>
                <div style="margin-bottom: 23px;">
                    <label style="width: 120px; text-align: right; margin-right: 40px;">资源类型</label>
                    <CSSelect
                        iWidth="36px"
                        height="40px"
                        style="width: 268px"
                    >
                        <select v-model="addResourceParams.isMenuItem">
                            <option value="">请选择</option>
                            <option :value="true">菜单</option>
                            <option :value="false">接口</option>
                        </select>
                    </CSSelect>
                </div>
                <div>
                    <label style="width: 120px; text-align: right; margin-right: 40px;">资源path</label>
                    <input
                        type="text"
                        v-model="addResourceParams.path"
                        style="border: 1px solid #999; border-radius: 4px;"
                        placeholder="请输入"
                    />
                </div>
            </div>
        </CSDialog>
        <!-- 资源启用/禁用 -->
        <CSDialog
            dialogTitle="提示" dialog-header-class=" "
            dialogWidth="480px" dialog-confirm-btn-text="确定"
            :dialogVisible="enableResourceModalInfo.visible"
            @onClose="enableResourceModalInfo.visible = false"
            @onConfirm="changeEnableStatus"
            dialog-header-icon="icon-menua-zu13"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px; text-align: center;"
            >
                {{enableResourceModalInfo.title}}
            </div>
        </CSDialog>
    </div>
</template>

<script>
import {
    createResourceUrl,
    disableResourceUrl,
    queryResourceUrl,
    enableResourceUrl,
    editResourceUrl,
} from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";

export default {
    name: "resourceManage",
    components: {
        CSDialog,
        CSSelect
    },
    created(){
        window.addEventListener("keydown",this.getResourceListDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getResourceListDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this.getResourceListDown);
    },
    data() {
        return {
            resourceList: [],
            // 正在查看的资源
            childrenResource: {},
            addResourceModalVisible: false,
            // 启用、禁用资源弹窗
            enableResourceModalInfo: {
                visible: false,
                title: "",
                data: {
                    id: "",
                    isEnable: false,
                },
            },
            queryParams: {
                serviceId: "",
                name: "",
                menuItem: "",
                path: "",
                enable: "",
                parentId: "",
                id: "",
            },
            addResourceParams: {
                id: "",
                serviceId: "",
                name: "",
                isMenuItem: "",
                parentId: 0,
                path: "",
            },
            addEditTitle: "",
        };
    },
    mounted() {
        this.getResourceList();
    },
    methods: {
        getResourceListDown(e){
            if(e.keyCode == 13){
                this.getResourceList();
            }
        },
        /**
         * 打开改变资源可用状态弹窗
         * @param {Number} id   资源id
         * @param {Boolean} isEnable 是否启用
         *  */
        openChangeEnableModal(id, isEnable) {
            this.enableResourceModalInfo = {
                visible: true,
                title: `确定${isEnable ? "启用" : "禁用"}资源吗?`,
                data: {
                    id,
                    isEnable,
                },
            };
        },
        // 改变资源状态
        changeEnableStatus() {
            const params = this.enableResourceModalInfo.data;
            this.$fly.post(enableResourceUrl, params).then((res) => {
                if (res.code !== 0) {
                    return;
                }
                this.$vc.toast(`${params.isEnable ? "启用" : "禁用"}成功`);
                this.enableResourceModalInfo.visible = false;
                this.getResourceList();
            });
        },
        // 添加子资源
        openAddChildrenResourceModal(parentId) {
            this.addResourceModalVisible = true;
            this.addResourceParams = {
                serviceId: "",
                name: "",
                isMenuItem: "",
                parentId,
                path: "",
            };
        },
        // 打开添加资源弹窗
        openAddResourceModal() {
            this.addEditTitle = "添加资源";
            this.addResourceModalVisible = true;
            this.addResourceParams = {
                serviceId: "",
                name: "",
                isMenuItem: "",
                parentId: 0,
                path: "",
            };
        },
        /**
         * 打开编辑资源弹窗
         * @param {Object}  resource 资源信息
         *  */
        openEidtResourceModal(resource) {
            this.addEditTitle = "修改资源";
            this.addResourceModalVisible = true;
            this.addResourceParams = {
                id: resource.id,
                serviceId: resource.serviceId,
                name: resource.name,
                isMenuItem: resource.menuItem,
                parentId: resource.parentId,
                path: resource.path,
            };
        },
        // 创建/编辑资源
        createOrEditResource() {
            const params = {
                ...this.addResourceParams,
            };

            this.$fly
                .post(params.id ? editResourceUrl : createResourceUrl, params)
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast(`${params.id ? "修改" : "添加"}成功`);
                    this.addResourceModalVisible = false;
                    this.getResourceList();
                });
        },
        // 获取资源列表
        getResourceList() {
            this.$fly.post(queryResourceUrl, this.queryParams).then((res) => {
                this.resourceList = res.data;
            });
        },
        // 查看下级资源
        lookChildrenResource(resource) {
            this.childrenResource = resource || [];
        },
    },
};
</script>

<style lang="stylus" scoped>
.list-group
    max-height 500px
    overflow-y auto
    &-item
        cursor pointer
        &.active
            background var(--pc-theme-color)
            color #fff
            .btn
                background #fff
                color var(--pc-theme-color)
.form-panel
    .form-item
        display flex
        flex-flow row nowrap
        align-items center
        font-size 20px
        &-label
            width 140px
            margin-right 20px
        input
            border 1px solid #979797
            padding 0 10px
            border-radius 4px
        &:not(:last-of-type)
            margin-bottom 20px
</style>
